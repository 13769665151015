import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiService } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import CustomSoftwareDevelopment from '~/views/Services/CustomSofware/CustomSoftware.view'

const CustomSoftwareDevelopmentPage = () => <CustomSoftwareDevelopment />

export default CustomSoftwareDevelopmentPage

export const query = graphql`
  query {
    strapiService(page_name: { eq: "Custom Software Development" }) {
      ...MetaTagInformationServices
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiService>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiService,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/services/custom-software-development/"
      isBreadcrumbs
      secondLevel="Services"
      secondLevelPath="/services/"
      current="Custom Software Development Services"
      currentPath="/services/custom-software-development/"
    />
  )
}
